import 'intl'
import 'intl/locale-data/jsonp/en'

export const penceToPounds = (pence: number): number => pence / 100
export const poundsToPence = (pounds: number): number => pounds * 100
export const currencyFormat = (
  balance: number,
  fractionDigits = 2,
  pennies = true,
  locale = 'en-GB',
  currency = 'GBP',
) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency,
  maximumFractionDigits: fractionDigits,
  minimumFractionDigits: fractionDigits,
})
  .format(pennies ? penceToPounds(balance) : balance)